@font-face {
  font-family: "normal";
  src: url("./Assets/Fonts/Poppins/Poppins-Regular.ttf");
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "medium";
  src: url("./Assets/Fonts/Poppins/Poppins-Medium.ttf");
}

:root {
  --primary: rgb(21, 20, 24);
  --secondary: rgb(135, 57, 250);
  --accent: rgb(119, 119, 121);
  --white: rgb(255, 255, 255);
  --diamond-color: rgb(30, 30, 30);
}
body {
  background-color: var(--primary);
}
body::-webkit-scrollbar {
  display: none;
}
.dots-class {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.dots-class li {
  height: 50px;
  width: 50px;
}

.dots-class li button {
  display: none;
}

.dots-class li::after {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: red;
}

.dots-class li::marker {
  display: none;
  content: "";
}

#react-bubbly-transitions__bubbles {
  --size: 200vw;
}

@media only screen and (min-width: 768px) {
  #react-bubbly-transitions__bubbles {
    --size: 125vw;
  }
}

.react-bubbly-transitions__first,
.react-bubbly-transitions__second {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 50%;
  translate: -50% 100%;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  animation-timing-function: ease-in-out;
}

.react-bubbly-transitions__first {
  animation-name: bubble-move;
}

.react-bubbly-transitions__second {
  animation-name: bubble-second-move;
}

@keyframes bubble-move {
  20% {
    border-radius: var(--size);
  }

  50%,
  100% {
    translate: -50% 0;
    border-radius: 0;
  }
}

@keyframes bubble-second-move {
  30% {
    translate: -50% 100%;
  }

  50% {
    border-radius: var(--size);
  }

  100% {
    translate: -50% 0;
    border-radius: 0;
  }
}

/* bubbly Link */
.react-bubbly-transitions__bubbly-link {
  background: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

/* src/Circle.css */
.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(22, 130, 230, 100);
  border-radius: 50%;
  pointer-events: none;
  transition: all 200ms;
}

.animate-circle-leave {
  animation-name: circle-leave;
  animation-duration: 300ms;
}

@keyframes circle-leave {
  0% {
    scale: 1;
  }
  100% {
    scale: 0;
  }
}