@import url("./media.module.css");

.content {
  overflow: hidden;
}

.contain {
  padding: 0 1rem;
  overflow: hidden;
}

.bg {
  background-color: var(--primary);
}

.diamond {
  transform: scale(1.3) translateX(0);
}

.diamondxl {
  position: absolute;
  top: -120px;
  left: -670px;
  width: 900px;
  height: 900px;
  transition: transform 0.25s ease 0s;
}

.diamondsm {
  position: absolute;
  /* top: 100px; */
  left: 230px;
  width: 40px;
  height: 40px;
  transition: transform 0.25s ease 0s;
}

.diamondmd {
  position: absolute;
  top: 30px;
  right: 300px;
  width: 100px;
  height: 100px;
  transition: transform 0.25s ease 0s;
}

.diamondmd2 {
  position: absolute;
  top: 400px;
  right: 60px;
  width: 150px;
  height: 150px;
  transition: transform 0.25s ease 0s;
}

.diamondsm2 {
  position: absolute;
  top: 300px;
  right: 60px;
  width: 40px;
  height: 40px;
  transition: transform 0.25s ease 0s;
}

.background {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: var(--primary);
  z-index: -1;
  overflow: hidden;
  height: 2400px;
}

.About {
  padding-top: 10rem;
  align-items: center;
  height: 30rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.description {
  flex-basis: 50%;
  color: var(--white);
  font-family: "normal";
}

.description .title {
  font-size: 2.7rem;
  margin-bottom: 0.2rem;
}

.description .span {
  color: var(--accent);
  font-size: 1.5rem;
}

.image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 132, 255, 1);
}

.profileImage {
  justify-content: center;
  align-items: center;
  display: flex;
}

.divide {
  position: absolute;
  top: 250px;
  left: -1100px;
  width: 4000px;
  height: 4000px;
  overflow: hidden;
  transition: transform 0.25s ease 0s;
  transform: scale(1) translateX(5%);
}

.IconMenuSocial {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.stickyRight {
  right: 0;
}

.darkIcon {
  color: #333333 !important;
}

.iconSocial {
  height: 4.5rem;
  width: 5rem;
  color: beige;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bgWhiteSection {
  background-color: #fbfbff;
}

.iconSocial img {
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
}

.scrollCircle {
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollY {
  margin-left: 1rem;
  color: var(--white);
  font-family: "normal";
}

.sideMenu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  background-color: var(--white);
  right: 0;
  transform: translateX(300px);
  transition: transform 0.6s;
  z-index: 100;
}

.shake {
  animation: shake 2s infinite linear;
  cursor: pointer;
}

.slidetoLeft {
  transform: translateX(0);
}

.slidetoRight {
  animation: slideRight 0.7s ease linear;
}

.disapear {
  animation: shadowdis 0.8s ease-out;
}

.shadow {
  animation: shadow 0.8s ease-out;
  visibility: visible;
  opacity: 1;
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes shadowdis {
  0% {
    background-color: rgba(0, 0, 0, 0.5);
  }

  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes shadow {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes shake {
  50% {
    transform: translateY(0.8rem);
  }
}

@media (min-width: 992px) {
  .contain {
    width: 1000px !important;
    margin: 0px auto;
  }
}

@media only screen and (max-width: 600px) {
  .description {
    flex-basis: 100%;
    text-align: center;
  }

  .profileImage {
    margin-top: 2rem;
    flex-basis: 100%;
  }

  .profileImage .image {
    height: 200px;
    width: 200px;
  }
}

.sliderItem {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin-left: 10px;
  margin-right: 10px;
}

.text {
  width: 50%;
  gap: 10px;
}

.text p{
  margin-bottom: 2rem;
}

.Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.item {
  position: relative;
  width: 40%;
  justify-content: center;
  align-items: center;
  height: 24rem;
}

.video {
  height: 336px;
  width: 150px;
  position: absolute;
  top: 10%;
  right: 40%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.picture {
  height: 306px;
  width: 150px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.text p {
  font-family: "normal";
}

.card {
  background: rgb(47, 46, 52);
  padding: 2rem 2rem;
  border-radius: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.connectWithMe {
  color: rgba(0, 132, 255, 1);
  font-size: 2rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contactFormTitle {
  color: var(--white, #fff);
  font-size: 1.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.inputClass {
  transition: all 0.3s ease;
  background-color: #242329;
  border: none;
  min-height: 50px;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
}
.inputClass:focus-visible {
  outline: 2px solid rgba(0, 132, 255, 1);
}
.formGroup {
  margin: 8px 16px;
  width: 100%;
}

.headerText {
  font-size: 2rem;
  font-weight: bold;
}

.listItemWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.cardItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.separated {
  margin-right: 1rem;
  position: relative;
}

.roundItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: #3982f7;
  font-weight: bolder;
  color: #fff;
}

.roundItem .span {
  color: #fff;
  font-family: "medium";
}

.cardTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.cardDescription {
  font-family: "normal";
  font-size: 14px;
}

.line {
  height: 100%;
  width: 3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3982f7;
  position: absolute;
}

.subTitle {
  font-size: 0.9rem;
  color: #333333;
  margin-left: 0.5rem;
}
